import i18n from '@/plugins/i18n'
import store from '@/store'
import vendorRoutes from '@/modules/vendors/routes/projectRouter'

export default [
  {
    path: 'payments-history',
    name: 'project-payments-history',
    component: () => import('@/modules/payments/PaymentsHistory.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t('common.history')} - OWQLO`,
      headerTitle: () => i18n.t('common.history'),
    },
    props: true,
  },
  {
    path: 'payment-types',
    name: 'project-payment-types',
    component: () => import('@/modules/payments/views/PaymentTypesView.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.paymentTypes')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.paymentTypes'),
    },
    props: true,
  },
  {
    path: 'buy/:buyId/payment-detail',
    name: 'project-payment-detail',
    component: () => import('@/modules/payments/views/PaymentDetailView.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.paymentDetail')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.paymentDetail'),
    },
    props: true,
  },
  ...vendorRoutes,
]