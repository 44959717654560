import { firestoreAction } from 'vuexfire'
import { db, FieldValue, callBackend } from '@/services/firebase'

export default {
  namespaced: true,
  state: () => ({
    dbHistory: [],
  }),
  actions: {
    // Read
    bindHistory: firestoreAction(({ bindFirestoreRef }, organizationId) => bindFirestoreRef('dbHistory', db.collection(`properties/${organizationId}/pushNotifications`))),

    // Create
    async create({ rootState }, { notification, scheduledDate = null }) {
      // Send or Schedule notification
      let scheduledTaskId = null
      if (scheduledDate) {
        scheduledTaskId = await callBackend('task-scheduler/create-task', {
          endpoint: 'notifications/push/create',
          payload: notification,
          dateInMs: scheduledDate.getTime(),
        })
      } else await callBackend('notifications/push/create', notification)

      // Save notification to DB
      const sendDate = scheduledDate || new Date()
      const currentUser = rootState.user.dbData
      const notificationRef = db.collection(`properties/${notification.context.organizationId}/pushNotifications`).doc()
      const dbNotification = {
        ...notification,
        id: notificationRef.id,
        scheduledTaskId,
        sendDate,
        createdAt: FieldValue.serverTimestamp(),
        createdBy: { id: currentUser.id, name: `${currentUser.firstName} ${currentUser.lastName}` },
      }
      return notificationRef.set(dbNotification)
    },

    // Delete
    async deleteScheduled(context, { organizationId, notification }) {
      // Cancel task
      await callBackend('task-scheduler/cancel-task', { id: notification.scheduledTaskId })
      // Delete from DB
      return db.collection(`properties/${organizationId}/pushNotifications`).doc(notification.id).delete()
    },
  },
}
