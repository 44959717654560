<template>
  <div class="d-flex flex-row align-center multiple-labels">
    <span class="d-inline-block text-truncate mr-3" v-text="defaultLabel" />
    <v-tooltip v-if="showTooltip" top color="white">
      <template #activator="{ on, attrs }">
        <span class="multiple-labels__number-additional-items" v-bind="attrs" v-on="on" v-text="` (+${labels.length - 1})`" />
      </template>
      <div class="tooltip-text pa-2" v-html="contentTooltip" />
    </v-tooltip>
  </div>
</template>

<script>
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'MultipleLabels',
  mixins: [formUtils],
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    summaryText: { type: String, default: null },
  },
  computed: {
    defaultLabel: ({ labels, summaryText }) => labels.length > 0 ? summaryText ?? labels[0] : '',
    showTooltip: ({ labels }) => labels.length > 1,
    contentTooltip: ({ labels }) => `• ${labels.reduce((acc, curr) => `${acc}<p>• ${curr}</p>`)}`,
  },
}
</script>
