import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/i18n'
import getInitialLocale from '@/utils/getInitialLocale'

Vue.use(VueI18n)

const locales = Object.keys(messages)
const initialLocale = getInitialLocale()
const locale = locales.some(availableLocale => availableLocale === initialLocale) ? initialLocale : 'en'
const fallbackLocale = 'en'

export default new VueI18n({ locale, fallbackLocale, messages })
