import { setCookie } from '@/utils/cookies'
import supportedLanguages from '@/utils/supportedLanguages'

export default {
  computed: {
    locales() {
      return supportedLanguages.map(lang => ({
        code: lang,
        language: this.$i18n.t(`locales.${lang}`),
      }))
    },
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale
      this.$vuetify.lang.current = locale
      setCookie('lang', locale)
      this.$store.commit('setLocale', locale)
    },
  },
}
