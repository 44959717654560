import { firestoreAction } from 'vuexfire'
import { db, FieldValue, callBackend } from '@/services/firebase'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
  }),
  getters: {
    data: state => state.dbData,
  },
  actions: {
    // Read
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, streamingId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/streamings`).doc(streamingId),
    )),
    unbind: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbData')),
    // Create
    async create(context, { organizationId, streamingId }) {
      const streamingRef = db.collection(`properties/${organizationId}/streamings`).doc(streamingId)
      const snapshot = await streamingRef.get()
      if (snapshot.exists) return false

      await streamingRef.set({
        id: streamingRef.id,
        status: 'notStarted',
        stats: {
          viewers: [],
          currentViewers: 0,
          currentHosts: 0,
        },
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      })
      return streamingRef.id
    },

    // Update
    async addViewer(context, { organizationId, streamingId, userId }) {
      await db.collection(`properties/${organizationId}/streamings`).doc(streamingId).update({
        'stats.viewers': FieldValue.arrayUnion(userId),
        'updatedAt': FieldValue.serverTimestamp(),
      })
    },
    async updateRecording(context, { organizationId, streamingId, recording }) {
      await db.collection(`properties/${organizationId}/streamings`).doc(streamingId).update({
        recording,
        updatedAt: FieldValue.serverTimestamp(),
      })
    },
    async start(context, { organizationId, streamingId }) {
      // We first modify database here because cloud function is slow and multiple people can click start button
      await db.collection(`properties/${organizationId}/streamings`).doc(streamingId).update({ status: 'running' })
      const res = callBackend('streamings/start', { organizationId, streamingId })
      return res
    },
    finish(context, { organizationId, streamingId }) {
      // Setting the status to 'finished' makes the app engine stats server call 'finishStreaming' cloud function
      return db.collection(`properties/${organizationId}/streamings`).doc(streamingId).update({ status: 'finished' })
    },

    // Other
    async setSharedScreenRecordingLayout({ state }, streamingId) {
      const { recordingTemp: { resourceId, sid } } = state.dbData
      await callBackend('streamings/set-shared-screen-recording-layout', { cname: streamingId, resourceId, sid })
      return true
    },
    async unsetSharedScreenRecordingLayout({ state }, streamingId) {
      const { resourceId, sid } = state.dbData.recordingTemp
      await callBackend('streamings/unset-shared-screen-recording-layout', { cname: streamingId, resourceId, sid })
      return true
    },
  },
}
