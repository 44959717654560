import { db, FieldValue } from '@/services/firebase'

export default {
  namespaced: true,
  actions: {
    // Create
    async create(context, { organizationId, projectId, activityId, data }) {
      const commentRef = db.collection(`properties/${organizationId}/projects/${projectId}/activities/${activityId}/comments`).doc()
      await commentRef.set({
        ...data,
        id: commentRef.id,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      })
      return commentRef.id
    },

    // Update
    async update(context, { organizationId, projectId, activityId, commentId, data }) {
      return db.collection(`properties/${organizationId}/projects/${projectId}/activities/${activityId}/comments`).doc(commentId).update({
        ...data,
        updatedAt: FieldValue.serverTimestamp(),
      })
    },
    async accept(context, { organizationId, projectId, activityId, commentId }) {
      return db.collection(`properties/${organizationId}/projects/${projectId}/activities/${activityId}/comments`).doc(commentId).update({
        accepted: true,
        updatedAt: FieldValue.serverTimestamp(),
      })
    },

    // Delete
    delete(context, { organizationId, projectId, activityId, commentId }) {
      return db.collection(`properties/${organizationId}/projects/${projectId}/activities/${activityId}/comments`).doc(commentId).delete()
    },
  },
}
