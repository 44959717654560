import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: '/streaming/organization/:organizationId/project/:projectId/activity/:activityId',
    name: 'streaming',
    component: () => import('@/modules/streaming/Streaming'),
    props: true,
    meta: {
      title: () => `${store.getters['activity/data'].name} - ${i18n.t('activity.type.streaming')} - OWQLO`,
      headerTitle: () => store.getters['activity/data'].name,
    },
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, activityId } = to.params
      store.commit('loader/show')
      if (store.state.project.dbData?.id !== projectId) await store.dispatch('project/bind', { organizationId, projectId })
      if (store.state.activity.dbData?.id !== activityId) await store.dispatch('activity/bind', { organizationId, projectId, activityId })
      if (store.state.streaming.dbData?.id !== store.state.activity.dbData.streamingChannel) {
        const entity = await store.dispatch('streaming/bind', { organizationId, streamingId: store.state.activity.dbData.streamingChannel })
        if (!entity) next({ name: '404' })
      }
      setTimeout(() => store.commit('loader/hide'), 200)
      next()
    },
  },
  {
    path: '/audience-streaming/organization/:organizationId/project/:projectId/activity/:activityId',
    name: 'audienceStreaming',
    component: () => import('@/modules/streaming/AudienceStreaming'),
    props: true,
    meta: {
      title: () => `${store.getters['activity/data'].name} - ${i18n.t('activity.type.streaming')} - OWQLO`,
      headerTitle: () => store.getters['activity/data'].name,
    },
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId, activityId } = to.params
      store.commit('loader/show')
      if (store.state.project.dbData?.id !== projectId) await store.dispatch('project/bind', { organizationId, projectId })
      if (store.state.activity.dbData?.id !== activityId) await store.dispatch('activity/bind', { organizationId, projectId, activityId })
      if (store.state.streaming.dbData?.id !== store.state.activity.dbData.streamingChannel) {
        const entity = await store.dispatch('streaming/bind', { organizationId, streamingId: store.state.activity.dbData.streamingChannel })
        if (!entity) next({ name: '404' })
      }
      setTimeout(() => store.commit('loader/hide'), 200)
      next()
    },
  },
]
