import rolesEnum from '@/enums/rolesEnum'

export default (isUserAdmin, isUserCollaborator) => {
  if (isUserAdmin) {
    return rolesEnum.ADMIN
  } if (isUserCollaborator) {
    return rolesEnum.COLLABORATOR
  }
  return ''
}
