import BaseFactory from './baseFactory'
// https://rockett.pw/posts/component-factories-for-vue
export default class LazyFactory extends BaseFactory {
  // eslint-disable-next-line no-useless-constructor
  constructor(context, filenameReplacementPattern) {
    super(context, filenameReplacementPattern)
  }

  static register(vue, context, filenameReplacementPattern) {
    const factory = new this(context, filenameReplacementPattern)

    factory.requireComponent.keys().forEach(contextFilename => {
      const { componentName, component } = factory.fetchComponent(contextFilename)
      vue.component(componentName, () => component)
    })
  }
}
