import StaticFactory from './staticFactory'
import LazyFactory from './lazyFactory'

export default (Vue, options = {}) => {
  // eslint-disable-next-line no-throw-literal
  if (!options.context) throw 'ComponentFactory needs an instance of require.context.'

  const wantsAsync = options.context.id.split(' ')[1] === 'lazy'
  const factory = wantsAsync ? LazyFactory : StaticFactory
  const { context, fileReplacementPattern } = options
  const defaultFileReplacementPattern = /.*\/(\w+).vue$/g

  // eslint-disable-next-line no-undef
  factory.register(Vue, context, fileReplacementPattern || defaultFileReplacementPattern)
}
