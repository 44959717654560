import { firestoreAction } from 'vuexfire'
import i18n from '@/plugins/i18n'
import { db, uploadFileAndGetObj, uploadFileAndGetUrl, deleteFileAndReturnSuccess, FieldValue, callBackend } from '@/services/firebase'
import { getI18n } from '@/services/deepl'
import logEvent from '@/utils/logEvent'
import getModifiedFields from '../../utils/getModifiedFields'

const getDbFields = async ({ organizationId, projectId, activityRef, activity, data, publish, rootGetters, dotNotation = true }) => {
  const banner = data.banner ? await uploadFileAndGetUrl(activityRef.path, data.banner) : null
  const attachments = await Promise.all(data.attachments.map(att => uploadFileAndGetObj(activityRef.path, att)))
  const modifiedFields = getModifiedFields(activity, { ...data, banner, attachments, published: publish, active: true, id: activityRef.id }, dotNotation)
  const newUserIds = Object.keys(modifiedFields).some(field => field.startsWith('userSources')) && rootGetters['project/isAcademy']
    ? await callBackend('activities/get-user-ids', { organizationId, projectId, userSources: data.userSources })
    : null
  return {
    ...modifiedFields,
    ...(!Object.keys(activity).length && { createdAt: FieldValue.serverTimestamp() }),
    ...(!activity.userIds && !newUserIds && { userIds: ['all'] }),
    ...(newUserIds && { userIds: newUserIds }),
    ...(rootGetters['project/needsTranslation'] && publish && {
      needsTranslation: true, // TODO: eliminar cuando en apps se refactorice
      i18n: await getI18n(rootGetters['project/language'], {}, data, ['description']), // TODO make this funcion take only modified fields and return its result flattened
    }),
    organizationId,
    projectId,
  }
}

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbComments: null,
  }),
  getters: {
    data: state => state.dbData,
    comments: state => state.dbComments,
  },
  actions: {
    // Read
    bind: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, activityId }) => bindFirestoreRef(
      'dbData',
      db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activityId),
    )),
    unbind: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbData')),
    bindComments: firestoreAction(({ bindFirestoreRef }, { organizationId, projectId, activityId }) => bindFirestoreRef(
      'dbComments',
      db.collection(`properties/${organizationId}/projects/${projectId}/activities/${activityId}/comments`).orderBy('updatedAt', 'desc'),
    )),

    // Create
    async create({ rootState, rootGetters }, { organizationId, projectId, data, publish = true }) {
      const activityRef = db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc()
      const activity = await getDbFields({ organizationId, projectId, activityRef, activity: {}, data, publish: false, rootGetters, dotNotation: false })
      if (activity.periodicity?.isPeriodic) {
        const periodicitySettings = { interval: activity.periodicity.interval, endDate: rootState.project.dbData.date.end.toDate() }
        await callBackend('activities/create-periodic-group', { organizationId, projectId, originalActivity: activity, periodicitySettings })
      } else await activityRef.set(activity)
      if (publish) {
        await callBackend('activities/update', {
          organizationId,
          projectId,
          activityId: activity.id,
          data: { published: true },
          targetPeriodicGroup: activity.periodicity?.isPeriodic,
          targetPastPeriodicActivities: false,
        })
      }
      logEvent({ action: 'create', entityType: 'activity', entity: activity })
      return activityRef.id
    },
    async createClone({ rootState }, { organizationId, projectId, activityId }) {
      const originalActivity = rootState.project.dbActivities.find(activity => activity.id === activityId)
      const newActivityRef = db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc()
      const newActivity = {
        ...originalActivity,
        id: newActivityRef.id,
        name: `${originalActivity.name} - ${i18n.t('common.copy')}`,
        published: false,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      }
      await newActivityRef.set(newActivity)
      logEvent({ action: 'create', entityType: 'activity', entity: newActivity })
      return newActivityRef.id
    },

    // Update
    async update(
      { rootGetters },
      { organizationId, projectId, activity, data, publish = true, targetPeriodicGroup = false, targetPastPeriodicActivities = false },
    ) {
      const activityRef = db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activity.id)
      const dataToUpdate = await getDbFields({ organizationId, projectId, activityRef, activity, data, publish, rootGetters })
      await callBackend('activities/update', {
        organizationId,
        projectId,
        activityId: activity.id,
        data: dataToUpdate,
        targetPeriodicGroup,
        targetPastPeriodicActivities,
      })
      if (publish) {
        logEvent({ action: 'update', entityType: 'activity', entity: activity })
        if (activity.name !== data.name) logEvent({ action: 'rename', entityType: 'activity', entity: activity, newName: data.name })
      }
      return true
    },
    async updateScore(context, { organizationId, projectId, activityId, teamsByIndex }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activityId).update({ teamsByIndex })
    },
    async updateMaxStock(context, { organizationId, projectId, activityId, maxStock }) {
      try {
        return await db.runTransaction(async transaction => {
          const activityRef = db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activityId)
          const activity = (await transaction.get(activityRef)).data()
          if (maxStock >= ((activity.maxStock ?? 0) - (activity.stock ?? 0))) {
            const newMaxStock = maxStock - (activity.maxStock ?? 0)
            transaction.update(activityRef, { maxStock, stock: FieldValue.increment(newMaxStock) })
            return true
          }
          return false
        })
      } catch (error) {
        console.error('Transaction failed: ', error)
        return false
      }
    },
    async cancel(context, { organizationId, projectId, data }) {
      const { activity: { id: activityId, periodicity: { isPeriodic } }, cancellationReason } = data
      await callBackend('activities/cancel', { organizationId, projectId, activityId, cancellationReason, targetPeriodicGroup: isPeriodic })
    },
    async archive(context, { organizationId, projectId, activity }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activity.id).update({ shipping: { status: 'delivered' } })
      logEvent({ action: 'archive', entityType: 'activity', entity: activity })
      return true
    },
    async unarchive(context, { organizationId, projectId, activity }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activity.id).update({ active: true, status: 'upToDate' })
      logEvent({ action: 'unarchive', entityType: 'activity', entity: activity })
      return true
    },

    // Delete
    async delete(context, { organizationId, projectId, activity, targetPeriodicGroup }) {
      await callBackend('activities/delete', { organizationId, projectId, activityId: activity.id, targetPeriodicGroup })
      logEvent({ action: 'delete', entityType: 'activity', entity: activity })
      return true
    },
    async deleteAttachment(context, { organizationId, projectId, activityId, attachment }) {
      await db.collection(`properties/${organizationId}/projects/${projectId}/activities`).doc(activityId).update({
        attachments: FieldValue.arrayRemove(attachment),
      })
      await deleteFileAndReturnSuccess(attachment.publicUrl)
    },
  },
}
