import i18n from '@/plugins/i18n'
import store from '@/store'
import subscriberRoutes from '@/modules/projectUsers/subscriber/routes/projectRouter'

export default [
  {
    path: 'subscribers',
    name: 'project-subscribers',
    component: () => import('@/modules/projectUsers/views/SubscriberListView.vue'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t(`project.sections.players-${store.getters['project/data']?.type}`)} - OWQLO`,
      headerTitle: () => i18n.t(`project.sections.players-${store.getters['project/data']?.type}`),
    },
    props: true,
  },
  {
    path: 'staff',
    name: 'project-staff',
    component: () => import('@/modules/projectUsers/views/StaffListView.vue'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.staff')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.staff'),
    },
    props: true,
  },
  {
    path: 'auto-generate-enrollments',
    name: 'project-auto-generate-enrollments',
    component: () => import('@/modules/projectUsers/AutoGenerateEnrollments.vue'),
    meta: {
      title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.autoGenerateEnrollments')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.autoGenerateEnrollments'),
    },
    props: true,
  },
  ...subscriberRoutes,
]
