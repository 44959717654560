import rolesEnum from '@/enums/rolesEnum'

export default (isUserClusterManager, isUserClusterCollaborator) => {
  if (isUserClusterManager) {
    return rolesEnum.MANAGER
  } if (isUserClusterCollaborator) {
    return rolesEnum.COLLABORATOR
  }
  return ''
}
