import Vue from 'vue'
import ComponentFactory from '@/factories/componentFactory'

Vue.use(ComponentFactory, {
  // To use the StaticFactory:
  context: require.context('@/components/presenters', true, /\w+\.vue$/),

  // To use the LazyFactory:
  // context: require.context('@/components/presenters', true, /\w+\.vue$/, 'lazy'),
})

export default ComponentFactory
