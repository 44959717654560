// https://rockett.pw/posts/component-factories-for-vue
export default class BaseFactory {
  constructor(requireContext, filenameReplacementPattern) {
    this.requireComponent = requireContext
    this.filenameReplacementPattern = filenameReplacementPattern
  }

  fetchComponent(contextFilename) {
    const component = this.requireComponent(contextFilename)
    const filename = contextFilename.replace(this.filenameReplacementPattern, '$1')
    const componentName = component.name || filename

    return { componentName, component, filename }
  }
}
