import { firestoreAction } from 'vuexfire'
import { db } from '@/services/firebase'

export default {
  namespaced: true,
  state: () => ({
    dbStats: null,
  }),
  getters: {
    stats: state => state.dbStats,
  },
  actions: {
    // Read
    bindStats: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef('dbStats', db.collection(`/properties/${id}/stats`).doc('basic'))),
    searchStatsRange: ({ state }, { id, list }) => Promise.all(list.map(date => db.collection(`properties/${id}/stats`).doc(date).get().then(snap => snap.data()))),
    async searchDataInitDate({ state }, id) {
      const initDate = await db.collection(`/properties/${id}/stats`).get()
      return initDate.docs.map(snap => snap.id)[0]
    },
  },
}
