import { firestoreAction } from 'vuexfire'
import { db, auth, FieldValue, callBackend, uploadFileAndGetUrl, uploadFileAndGetObj, Timestamp } from '@/services/firebase'
import getUserEntityIds from '@/utils/getUserEntityIds'
import { subscribeToEntity } from '@/utils/entitySubscriptions'
import rolesEnum from '@/enums/rolesEnum'
import router from '@/router'

export default {
  namespaced: true,
  state: () => ({
    dbData: null,
    dbUserAddress: null,
    dbParentAddress: null,
    dbProjectMembership: null,
    dbOrganizationMembership: null,
  }),
  getters: {
    data: state => state.dbData,
    userAddress: state => state.dbUserAddress?.[0],
    parentAddress: state => state.dbParentAddress?.[0],
    membership: state => ({ ...state.dbProjectMembership, organization: state.dbOrganizationMembership }),
    isAdmin: state => state.dbData.roles.roles?.includes('superadmin'),
    adminOrganizationIds: state => getUserEntityIds(state.dbData, 'property', rolesEnum.ADMIN),
    laligaAdminOrganizationIds: state => getUserEntityIds(state.dbData, 'property', rolesEnum.LALIGADMIN),
    collabOrganizationIds: state => getUserEntityIds(state.dbData, 'property', rolesEnum.COLLABORATOR),
    staffProjectIds: state => getUserEntityIds(state.dbData, 'project', 'staff'),
    staffProjects: (state, getters, rootState, rootGetters) => (rootGetters['organization/projects'].length
      ? rootGetters['organization/projects']
      : rootGetters['cluster/projects'])
      .filter(p => getters.staffProjectIds.includes(p.id)),
    collabClusterIds: (state, getters) => [...new Set(getters.staffProjects.filter(p => p.clusterId).map(p => p.clusterId))],
    shipperOrganizationIds: state => getUserEntityIds(state.dbData, 'property', rolesEnum.SHIPPER),
    managerClusterIds: state => getUserEntityIds(state.dbData, 'cluster', rolesEnum.MANAGER),
    managerVendorIds: state => getUserEntityIds(state.dbData, 'vendor', rolesEnum.MANAGER),
    staffTeamIds: state => getUserEntityIds(state.dbData, 'team', 'staff'),
    playerTeamIds: state => getUserEntityIds(state.dbData, 'team', rolesEnum.PLAYER),
    collabClubs: state => Object.entries(state.dbData.roles.byProperty ?? {})
      .flatMap(([organizationId, { byProject = {} }]) => Object.entries(byProject)
        .flatMap(([projectId, { byClub = {} }]) => Object.entries(byClub)
          .filter(([, { roles = [] }]) => roles.includes('collaborator'))
          .map(([clubId]) => ({ organizationId, projectId, id: clubId })))),
    collabClubIds: (state, getters) => getters.collabClubs.map(club => club.id),
  },
  actions: {
    // Read
    bind: firestoreAction(({ bindFirestoreRef }, id) => bindFirestoreRef('dbData', db.collection('users').doc(id))),
    bindUserAddress: firestoreAction(({ bindFirestoreRef }, { userId }) => bindFirestoreRef(
      'dbUserAddress',
      db.collection(`users/${userId}/addresses`).limit(1),
    )),
    bindParentAddress: firestoreAction(({ bindFirestoreRef }, { parentId }) => bindFirestoreRef(
      'dbParentAddress',
      db.collection(`users/${parentId}/addresses`).limit(1),
    )),
    bindProjectMembership: firestoreAction(({ bindFirestoreRef }, { id, projectId, role }) => bindFirestoreRef(
      'dbProjectMembership',
      db.collection(`users/${id}/membership`).doc(`${role}-project-${projectId}`),
    )),
    bindOrganizationMembership: firestoreAction(({ bindFirestoreRef }, { id, organizationId, role }) => bindFirestoreRef(
      'dbOrganizationMembership',
      db.collection(`users/${id}/membership`).doc(`${role}-organization-${organizationId}`),
    )),
    unbind: firestoreAction(({ unbindFirestoreRef }) => unbindFirestoreRef('dbData')),
    link: (context, { user1, user2 }) => callBackend('users/link', { user1, user2 }),
    async read(context, id) { return (await db.collection('users').doc(id).get()).data() },
    async readByEmail(context, email) {
      if (!email) return null
      const user = (await db.collection('users').where('email', '==', email).get()).docs[0]?.data()
      return user ?? null
    },
    async readMembershipInfo(context, { id, role, entityType, entityId }) {
      return (await db.collection(`users/${id}/membership`).doc(`${role}-${entityType}-${entityId}`).get()).data()
    },
    async readBuys(context, { shippingId }) {
      const collectionSnap = await db.collection('buys').where('shippingId', '==', shippingId).get()
      return collectionSnap.docs.map(snap => snap.data())
    },
    readUserAddress: async (context, { userId }) => (await db.collection(`users/${userId}/addresses`).limit(1).get()).docs.map(s => s.data())[0],
    async getInvitation(context, email) { return (await db.collection('invitations').doc(email).get()).data() },
    async getMembershipsToAccept(context, { userId }) {
      return (await db.collection(`users/${userId}/membership`).where('hasAcceptedTerms', '==', false).get()).docs.map(snap => snap.data())
    },
    async readUserPaymentMethod(context, { userId, paymentMethodId }) {
      return (await db.collection(`users/${userId}/paymentMethods`).doc(paymentMethodId).get()).data()
    },

    // Create
    async create({ rootState }, { email, password, secretKey, data }) {
      const lang = rootState.locale
      const invitation = (await db.collection('invitations').doc(email).get()).data()
      if (invitation.secretKey !== secretKey) throw new Error('Invalid secretKey')
      const { user: { uid } } = await auth.createUserWithEmailAndPassword(email, password)
      await db.collection('users').doc(uid).set({
        roles: {},
        ...data,
        email,
        id: uid,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      })
      if (invitation.entitiesToSubscribe?.length) {
        await Promise.all(invitation.entitiesToSubscribe.map(entity => subscribeToEntity({ userId: uid, entity, lang, isStaff: true })))
      }
      await callBackend('organizations/users/subscribe', { organizationId: 'lvoiMXzYuyzM4DrV2CbZ', userId: uid, role: 'fan' })
      return db.collection('invitations').doc(email).delete()
    },
    addPaymentNoteSuscriberOrganization(context, { organizationId, subscriberId, comments }) {
      const membership = context.getters.membership
      const documentRef = db.collection(`users/${subscriberId}/membership`).doc(`subscriber-organization-${organizationId}`)
      db.runTransaction(transaction => transaction.get(documentRef).then(document => {
        const paymentsNotes = membership.organization?.paymentsNotes ?? []
        const user = context.getters.data
        const paymentsNoteToAdd = { createdAt: Timestamp.now(), userId: user.id, userName: `${user.firstName} ${user.lastName}`, comments }
        const paymentsNotesToUpdate = [...paymentsNotes, paymentsNoteToAdd]

        transaction.update(documentRef, { paymentsNotes: paymentsNotesToUpdate })
      })).catch(error => {
        console.error('Transaction failed: ', error)
      })
    },

    async createWithBackend(context, { data, email }) {
      const dataToSave = (({ avatar, phone, ...rest }) => ({ ...rest }))(data)
      const { id } = await callBackend('users/create-programatically', { data: dataToSave, email, ...(data.phone && { phone: data.phone }) })

      if (data.avatar) {
        const avatarUrl = await uploadFileAndGetUrl(`users/${id}`, data.avatar)
        await db.collection('users').doc(id).update({ avatar: avatarUrl, updatedAt: FieldValue.serverTimestamp() })
      }

      return id
    },
    // Update
    update(context, { id, data }) {
      return db.collection('users').doc(id).update({
        ...data,
        updatedAt: FieldValue.serverTimestamp(),
      })
    },
    async updateProfileFields(context, { userId, data }) {
      const userRef = db.collection('users').doc(userId)
      if (data.avatar) data.avatar = await uploadFileAndGetUrl(`users/${userId}`, data.avatar)
      await userRef.update({ ...data, updatedAt: FieldValue.serverTimestamp() })
    },
    async createAddress(context, { userId, data }) {
      const addressRef = db.collection(`users/${userId}/addresses`).doc()
      await addressRef.set({ id: addressRef.id, ...data, createdAt: FieldValue.serverTimestamp(), updatedAt: FieldValue.serverTimestamp() })
    },
    async updateAddress(context, { userId, addressId, data }) {
      const addressRef = db.collection(`users/${userId}/addresses`).doc(addressId)
      await addressRef.update({ ...data, updatedAt: FieldValue.serverTimestamp() })
    },
    async updateMembership(context, { id, role, entityType, entityId, data }) {
      const ref = db.collection(`users/${id}/membership`).doc(`${role}-${entityType}-${entityId}`)
      const attachments = data.attachments ? await Promise.all(data.attachments.map(file => uploadFileAndGetObj(ref.path, file))) : []
      const paymentsAttachments = data.paymentsAttachments ? await Promise.all(data.paymentsAttachments.map(file => uploadFileAndGetObj(ref.path, file))) : []

      const storagePath = `users/${id}/membership/subscriber-project-${entityId}/`
      if (data.form) {
        data.form = await context.dispatch('dynamicForm/parseForm', { form: data.form, storagePath }, { root: true })
      }

      await ref.update({ ...data, attachments, paymentsAttachments })
    },
    async updateProjectMembershipParentFields(context, { id, role, entityType, entityId, data }) {
      try {
        await db.runTransaction(async transaction => {
          const ref = db.collection(`users/${id}/membership`).doc(`${role}-${entityType}-${entityId}`)
          const storagePath = `users/${id}/membership/subscriber-project-${entityId}/`
          if (data.form) {
            data.form = await context.dispatch('dynamicForm/parseForm', { form: data.form, storagePath }, { root: true })
          }

          if (data.form) {
            const dataMembership = (await transaction.get(ref)).data()
            const fieldIdsParents = context.rootGetters['dynamicForm/dataUser'].fields.filter(f => f.requestedOnceForAllChildren).map(f => f.id)
            const fieldIdsChildren = context.rootGetters['dynamicForm/dataUser'].fields.filter(f => !f.requestedOnceForAllChildren).map(f => f.id)

            const childrenForm = Object.fromEntries(Object.entries(dataMembership.form ?? {}).filter(([key]) => fieldIdsChildren.includes(key)))

            const parentFormValues = await context.dispatch('dynamicForm/parseForm', { form: data.form, storagePath }, { root: true })
            const parentForm = Object.fromEntries(Object.entries(parentFormValues ?? {}).filter(([key]) => fieldIdsParents.includes(key)))
            data.form = { ...childrenForm, ...parentForm }
          }
          transaction.update(ref, { ...data })
        })
      } catch (error) {
        console.error('Transaction failed: ', error)
        return false
      }
    },
    async updateProjectForm({ rootGetters, dispatch }, { id, organizationId, projectId, data }) {
      // Update project membership form
      const path = `users/${id}/membership/subscriber-project-${projectId}/`
      const form = await dispatch('dynamicForm/parseForm', { form: data, storagePath: path }, { root: true })
      await db.doc(path).update({ form })
    },
    async updateOrganizationTerms(context, { userId, organizationId }) {
      await db.collection(`users/${userId}/membership`).doc(`subscriber-organization-${organizationId}`).update({
        hasAcceptedTerms: true,
      })
      router.push({ name: 'redirectAfterLogin' }).catch(() => {})
    },
    async updateUserMembershipCard(context, { organizationId, projectId, userId, membershipCardId }) {
      const userMembershipCardId = `${organizationId}-${projectId}-${membershipCardId}`
      const userMembershipCardRef = db.collection(`users/${userId}/userMembershipCards`).doc(userMembershipCardId)
      const { firstName, lastName } = await context.dispatch('read', userId)
      const memberCode = await callBackend('projects/users/generate-member-code', { organizationId, projectId, membershipCardId })
      await userMembershipCardRef.set({
        id: userMembershipCardId,
        userId,
        membershipCardId,
        organizationId,
        projectId,
        memberCode,
        userName: `${firstName} ${lastName}`,
        active: true,
        isFree: true,
        createdAt: FieldValue.serverTimestamp(),
        updatedAt: FieldValue.serverTimestamp(),
      })
    },
    addFavoriteOrganization(context, { id, organizationId }) {
      return db.collection('users').doc(id).update({ 'favorites.organizations': FieldValue.arrayUnion(organizationId) })
    },
    removeFavoriteOrganization(context, { id, organizationId }) {
      return db.collection('users').doc(id).update({ 'favorites.organizations': FieldValue.arrayRemove(organizationId) })
    },
    addFavoritePartner(context, { id, partnerId }) {
      return db.collection('users').doc(id).update({ 'favorites.partners': FieldValue.arrayUnion(partnerId) })
    },
    removeFavoritePartner(context, { id, partnerId }) {
      return db.collection('users').doc(id).update({ 'favorites.partners': FieldValue.arrayRemove(partnerId) })
    },
    addFavoriteProject(context, { id, projectId }) {
      return db.collection('users').doc(id).update({ 'favorites.projects': FieldValue.arrayUnion(projectId) })
    },
    addFavoriteCluster: (context, { id, clusterId }) => db.collection('users').doc(id).update({ 'favorites.clusters': FieldValue.arrayUnion(clusterId) }),
    removeFavoriteProject(context, { id, projectId }) {
      return db.collection('users').doc(id).update({ 'favorites.projects': FieldValue.arrayRemove(projectId) })
    },
    removeFavoriteCluster: (context, { id, clusterId }) => db.collection('users').doc(id).update({ 'favorites.clusters': FieldValue.arrayRemove(clusterId) }),
    updateBuy: (context, { buyId, shippingStatus = null }) => db.collection('buys').doc(buyId).update({ 'shipping.status': shippingStatus, 'shipping.deliveryDate': FieldValue.serverTimestamp() }),

    // Delete
    async deleteUserMembershipCard(context, { userId, userMembershipCardId }) {
      await db.collection(`users/${userId}/userMembershipCards`).doc(userMembershipCardId).delete()
    },

    // Other
    async login({ dispatch }, { email, password }) {
      const { user: { uid } } = await auth.signInWithEmailAndPassword(email, password)
      return dispatch('bind', uid)
    },
    async logout({ dispatch }) {
      await auth.signOut()
      return dispatch('unbind')
    },
    async setPassword({ dispatch }, { oobCode, password }) {
      await auth.verifyPasswordResetCode(oobCode)
      await auth.confirmPasswordReset(oobCode, password)
      return true
    },
    async verifyEmailCode({ dispatch }, { oobCode }) {
      await auth.applyActionCode(oobCode)
      return true
    },
    async resetPassword(context, email) {
      const user = (await db.collection('users').where('email', '==', email).get()).docs[0]?.data()
      if (!user) throw new Error('There is no user with that email')
      await callBackend('users/send-custom-password-reset-email', { email })
      return true
    },
    async toggleAcceptedCheckbox(context, { organizationId, projectId, clubId, value }) {
      const storageURL = `properties/${organizationId}/projects/${projectId}/clubs`
      const collectionRef = db
        .collection(storageURL)
        .doc(clubId)
      await collectionRef.set(
        {
          collaboratorUser: {
            hasAcceptedCheckbox: value,
          },
        },
        {
          merge: true,
        },
      )
    },
  },
}
