import { isObject } from 'lodash'

export default (user, entityType, role) => {
  const getIds = (obj, field, role) => Object.entries(obj ?? {})
    .flatMap(([key, val]) => key === field
      // temp solution until make script
      ? Object.entries(val).filter(([, { roles = [] }]) => roles.includes(role.charAt(0).toUpperCase() + role.slice(1)) || roles.includes(role))
        .map(([id]) => id)
      : getIds(isObject(val) ? val : {}, field, role))

  return getIds(user, `by${entityType[0].toUpperCase()}${entityType.slice(1)}`, role)
}
