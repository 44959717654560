import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'tickets',
    name: 'cluster-tickets',
    component: () => import('@/modules/guildMatches/GuildMatchList'),
    meta: {
      title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('organization.sections.tickets')} - OWQLO`,
      headerTitle: () => i18n.t('organization.sections.tickets'),
    },
    props: true,
  },
  {
    path: 'tickets/match-detail/:guildMatchId',
    name: 'cluster-tickets-match-detail',
    component: () => import('@/modules/guildMatches/MatchDetail'),
    meta: {
      title: () => `${store.getters['cluster/data']?.name} - ${i18n.t('organization.sections.guildMatchDetail')} - OWQLO`,
      headerTitle: () => i18n.t('organization.sections.tickets'),
    },
    props: true,
  },
]
