import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'payments-history',
    name: 'organization-payments-history',
    component: () => import('@/modules/payments/PaymentsHistory.vue'),
    meta: {
      title: () => `${store.state.organization.dbData?.name} - ${i18n.t('common.history')} - OWQLO`,
      headerTitle: () => i18n.t('common.history'),
    },
    props: true,
  },
  {
    path: 'buy/:buyId/payment-detail',
    name: 'organization-payment-detail',
    component: () => import('@/modules/payments/views/PaymentDetailView.vue'),
    meta: {
      title: () => `${store.getters['organization/data']?.name} - ${i18n.t('project.sections.paymentDetail')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.paymentDetail'),
    },
    props: true,
  },
]