import deepl from 'deepl'
import { get } from 'lodash'
import supportedLanguages from '@/utils/supportedLanguages'

const AuthKey = '6101674b-d1a9-71d4-ecf0-08ee2eb30e88:fx'

export const getI18n = async (originalLang, previousObj, currentObj, fieldsToTranslate) => {
  const i18n = Object.fromEntries(await Promise.all(supportedLanguages.map(async lang => (
    [lang, Object.fromEntries(await Promise.all(fieldsToTranslate.map(async field => (
      [field, lang === originalLang
        ? currentObj[field]
        : previousObj[field] === currentObj[field] && get(currentObj, `i18n.${lang}.${field}`, null)
          ? get(currentObj, `i18n.${lang}.${field}`)
          : await translate(currentObj[field], lang)]
    ))))]
  ))))
  return i18n
}

export const translate = async (textToTranslate, lang) => {
  if (process.env.VUE_APP_IS_PRODUCTION !== 'true') {
    return `${lang}__${textToTranslate}`
  }

  try {
    const { data: { translations } } = await deepl({
      auth_key: AuthKey,
      text: textToTranslate,
      target_lang: lang.toUpperCase(),
    })

    const { text } = translations[0]
    return text
  } catch (e) {
    return textToTranslate
  }
}
