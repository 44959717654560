import store from '@/store'
import { db, FieldValue } from '@/services/firebase'
import projectRouteNames from '@/enums/projectRouteNames'

export default ({ action, entityType, entity, targetUser = null, newName = null }) => {
  // Get user and targetUser data
  const userId = store.state.user.dbData.id
  const userName = `${store.state.user.dbData.firstName} ${store.state.user.dbData.lastName}`
  const targetUserName = targetUser && `${targetUser.firstName} ${targetUser.lastName}`

  // Get context data
  const getOrganizationId = () => { // Always present
    if (entityType === projectRouteNames.ORGANIZATION) return entity.id
    return store.state.organization.dbData.id
  }
  const organizationId = getOrganizationId()
  const getProjectId = () => {
    if (entityType === projectRouteNames.PROJECT) return entity.id
    if ([
      projectRouteNames.ACTIVITY,
      projectRouteNames.POST,
    ].includes(entityType)) return store.state.project.dbData.id
    return null
  }
  const projectId = getProjectId()
  const activityId = entityType === projectRouteNames.ACTIVITY ? entity.id : null
  const postId = entityType === projectRouteNames.POST ? entity.id : null
  // Write database
  db.collection(`properties/${organizationId}/eventLog`).doc('eventLog').set({
    events: FieldValue.arrayUnion({
      user: { id: userId, name: userName },
      action,
      entity: {
        type: entityType,
        name: entity?.name ?? entity?.title,
      },
      context: {
        organizationId,
        projectId,
        ...(activityId && ({ activityId })),
        ...(postId && ({ postId })),
      },
      ...(targetUser && { targetUser: { role: targetUser.role, id: targetUser.id, name: targetUserName } }),
      ...(newName && { newName }),
      createdAt: new Date(),
    }),
  }, { merge: true })
  return true
}
