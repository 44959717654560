<template>
  <span>
    {{ hours | addCeroIfNeeded }}
    {{ ':' }}
    {{ minutes | addCeroIfNeeded }}
    {{ ':' }}
    {{ seconds | addCeroIfNeeded }}
  </span>
</template>

<script>
export default {
  name: 'CounterDown',
  filters: {
    addCeroIfNeeded: num => `${num}`.padStart(2, '0'),
  },
  props: {
    date: {
      type: Date,
      default: '',
    },
  },
  data() {
    return {
      distance: null,
    }
  },
  computed: {
    hours: ({ distance }) => Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: ({ distance }) => Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: ({ distance }) => Math.floor((distance % (1000 * 60)) / 1000),
  },
  created() {
    this.distance = this.date - new Date().getTime()
  },
  methods: {
  },
}
</script>
