import jsPDF from 'jspdf'

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

const getWidthAndHeight = (file, maxWidth, maxHeight) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = theFile => {
    const image = new Image()
    image.src = theFile.target.result
    image.onload = () => {
      const ratio = Math.min(maxWidth / +image.width, maxHeight / +image.height)
      resolve({ width: (+image.width) * ratio, height: (+image.height) * ratio })
    }
  }
})

export default async file => {
  const imageBase64 = await toBase64(file)
  const doc = new jsPDF('p', 'px', 'a4')
  const { width, height } = await getWidthAndHeight(file, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight())
  doc.addImage(imageBase64, 0, 0, width, height)
  return doc.output('blob')
}
