import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'push-notifications',
    name: 'organization-pushNotifications',
    component: () => import('@/modules/pushNotifications/PushNotifications'),
    redirect: { name: 'organization-pushNotifications-create' },
    props: true,
    children: [
      {
        path: 'create',
        name: 'organization-pushNotifications-create',
        component: () => import('@/modules/pushNotifications/Create'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.pushNotifications')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.pushNotifications'),
        },
        props: true,
      },
      {
        path: 'history',
        name: 'organization-pushNotifications-history',
        component: () => import('@/modules/pushNotifications/History'),
        meta: {
          title: () => `${store.state.organization.dbData?.name} - ${i18n.t('organization.sections.pushNotifications')} - OWQLO`,
          headerTitle: () => i18n.t('organization.sections.pushNotifications'),
        },
        props: true,
      },
    ],
  },
]
